import React from "react";
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  ListItemIcon,
  SvgIcon,
  List,
  Chip,
  IconButton,
  Typography,
  Card,
} from "@material-ui/core";
import {ReactComponent as DescriptionIcon} from "assets/icons/description.svg";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import GuidebookContentTypeInput from "core/inputs/GuidebookContentTypeInput";
import Conditions from "components/Panels/Experience/ExperienceContent/Conditions/Conditions";
import LanguageSelector from "components/MultiOption/LanguageSelector";
import {ReactComponent as DragIcon} from "assets/icons/Icon_Reorder.svg";
import CreateFromExistingList from "components/Lists/CreateFromExistingList";
import TemplateCard from "components/Cards/TemplateCard";
import useGuidebooksStyles from "styles/useGuidebooksStyles";
import {useTranslation} from "react-i18next";
import PrimaryButton from "core/buttons/PrimaryButton";
import ExpandButton from "core/buttons/ExpandButton";
import FilledTextField from "core/inputs/FilledTextField";
import ConnectedToButton from "core/buttons/ConnectedToButton";
import FieldTypeIcon from "core/icons/FieldTypeIcon";
import CustomMenu from "core/menus/CustomMenu";
import CloseIcon from "@material-ui/icons/Close";
import {newUuid, getEmailContentWrapper} from "utilities/helperFunctions";
import {contentTypeEnum, guidebookTypeEnum} from "configuration/enums";
import UpdateKnowledgeInput from "core/inputs/UpdateKnowledgeInput";
import EnsoAIButton from "components/Misc/EnsoAIButton";
import clsx from "clsx";
import {createAction} from "redux/actions/experiencesActions";
import {useDispatch} from "react-redux";

const GuidebookEditor = ({
  disableEdit,
  selectedGuidebook,
  externalListings,
  listingsOpen,
  setListingsOpen,
  setEmptyListings,
  listing,
  groupId,
  isGroupView,
  setSelectedGuidebook,
  conditions,
  setConditions,
  setContent,
  content,
  selectedLang,
  handleNameChange,
  handleContentValidity,
  selectedGroup,
  guidebooks,
  AIListingData,
}) => {
  const classes = useGuidebooksStyles();
  const [fieldsAnchorEl, setFieldsAnchorEl] = React.useState(null);
  const [address, setAddress] = React.useState([]);
  let dispatch = useDispatch();
  let data = content?.components ?? [];
  const [selectedComponent, setSelectedComponent] = React.useState(
    data?.length === 1 && data?.[0]?.id,
  );
  const {t} = useTranslation();

  React.useEffect(() => {
    const observer = new MutationObserver(() => {
      const dropdowns = document.querySelectorAll(".pac-container");
      dropdowns.forEach((dropdown) => {
        dropdown.style.zIndex = "10000";
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  function setData(d) {
    setContent({...content, components: d});
  }

  const handleEditField = (i, field, val) => {
    let newData = [...data];
    if (field == null) {
      newData[i] = val;
    } else {
      newData[i] = {...newData[i], [field]: val};
    }
    setData(newData);
  };

  const handleRemoveField = (ind) => {
    let newData = data.filter((ed, i) => i !== ind);
    setData(newData);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(data);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    setData(newItems);
  };

  const handleAddField = (fieldType) => {
    const newField = {
      id: newUuid(),
      type: fieldType.type,
      name: fieldType.name,
      text: [{language: "en", value: ""}],

      // header
      label: [{language: "en", value: ""}],

      // button
      button_type: "external_link",

      // Add other default properties here if needed
    };
    setData([...data, newField]);
    setFieldsAnchorEl(null);
    setSelectedComponent(newField.id);
  };

  const handleGenerateFromTemplate = (prompt) => {
    setContent({...content, is_template: false, ai_loading: true});
    dispatch(
      createAction({
        customErrorMessage:
          "EnsoAI is experiencing high traffic. Please try again later",
        listingId: listing?.listing_id,
        action: {
          action_id: "generate_text",
          content_type: "guidebook_components",
          context_article_types: content.template_spec?.content_types || [
            "Amenity",
          ],
          prompt: {
            name: content?.name[selectedLang],
            components: data,
          },
        },
        onSuccess: (r) => {
          console.log("GENERATED GUIDEBOOK COMPONENTS", r);
          if (!r?.action?.response?.updated_components) {
            return;
          }
          setContent({
            ...content,
            ai_loading: false,
            is_template: false,
            components: r?.action?.response?.updated_components,
          });
        },
        onError: (err) => {
          setContent({
            ai_loading: false,
          });
        },
      }),
    );
  };

  function getMenuContent() {
    const hiddenFieldTypes = ["email", "phone", "address"];
    const fieldList = [
      {name: "Place", icon: "guest_address", type: "place"},
      {name: "Summary", icon: "multi_select", type: "summary"},
      {name: "Header", icon: "text", type: "header"},
      {name: "List", icon: "multi_select", type: "list"},
      {name: "Text", icon: "text", type: "text"},
      {name: "Button", icon: "number", type: "button"},
      {name: "Video", icon: "image", type: "video"},
      {name: "Image", icon: "image", type: "image"},
    ];

    return (
      <MenuList>
        <MenuItem key="select-field" disabled value="">
          Select a field
        </MenuItem>
        {fieldList.map((cftk) => (
          <MenuItem
            button
            disableRipple
            key={cftk.type}
            onClick={() => handleAddField(cftk)}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <FieldTypeIcon type={cftk.icon} lg />
            </ListItemIcon>
            <Typography>{cftk.name}</Typography>
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  const fieldsMenu = (
    <CustomMenu
      zIndex={1300}
      open={Boolean(fieldsAnchorEl)}
      anchorEl={fieldsAnchorEl}
      onClose={() => setFieldsAnchorEl((prev) => null)}
      content={getMenuContent()}
    />
  );

  return (
    <>
      {fieldsMenu}
      <Box height={listingsOpen ? "100%" : "fit-content"}>
        <ConnectedToButton
          disabled={!!disableEdit}
          data={selectedGuidebook}
          showSectionTitle
          type={"guidebook"}
          specificListings={selectedGroup?.connected_to?.ids}
          externalListings={externalListings}
          listingsOpen={listingsOpen}
          setListingsOpen={setListingsOpen}
          setEmptyListings={setEmptyListings}
          listing={listing}
          listing_id={isGroupView ? null : listing?.listing_id}
          group_id={groupId}
          isGroupView={isGroupView}
          editData={setSelectedGuidebook}
        />
        <Divider className={classes.divider} />
        {!listingsOpen && (
          <>
            <Conditions
              standalone
              title="Conditions"
              disableEdit={disableEdit}
              label="Define what conditions must be met to display this guidebook"
              data={conditions}
              setData={setConditions}
            />
            <Divider className={classes.divider} />
            <div className={classes.sectionTitle}>
              <SvgIcon
                className="icon"
                viewBox="0 0 16 17"
                component={DescriptionIcon}
              />
              <Typography className="title" variant="h1">
                {"Content"}
              </Typography>
            </div>
            <div className={classes.editorContainer}>
              <FilledTextField
                fullWidth
                label="Guidebook name"
                value={content.name[selectedLang]}
                disabled={!!disableEdit}
                className="mb-3"
                placeholder="e.g: General information"
                onChange={handleNameChange}
                onValidityCheck={handleContentValidity}
              />
              <div style={{display: "flex", marginBottom: 15}}>
                <div style={{flex: 1}} />
                {content?.is_template && content.guidebook_type !== "place" && (
                  <EnsoAIButton
                    handleGeneratedText={(text) =>
                      handleGenerateFromTemplate(text)
                    }
                    type="fill_template"
                    description="EnsoAI will customize this template using listing content from the AI knowledge base. This feature is in BETA - please ensure that generated content is accurate"
                    title="Fill template with EnsoAI"
                    default_prompt="Customize this template with my listing information"
                    listing={listing}
                  />
                )}
                <ExpandButton
                  hasPopup
                  label={`Add field`}
                  size="small"
                  variant="contained"
                  disabled={data.length === 40}
                  style={{marginLeft: 10}}
                  onClick={(e) => setFieldsAnchorEl(e.currentTarget)}
                />
              </div>
              <div className={classes.formContainer}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <List
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {data.map((f, i) => (
                          <Draggable key={f.id} draggableId={f.id} index={i}>
                            {(provided) => (
                              <Box
                                mb={3}
                                component={"div"}
                                className={classes.row}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <SvgIcon
                                  viewBox="0 0 14 15"
                                  component={DragIcon}
                                  className={classes.dragIcon}
                                />
                                <Box flex={1}>
                                  <GuidebookContentTypeInput
                                    isCollapsed={selectedComponent !== f.id}
                                    setIsCollapsed={(e) =>
                                      setSelectedComponent(e ? null : f.id)
                                    }
                                    field={f}
                                    loading={content.ai_loading || f.loading}
                                    key={`field-${i}`}
                                    onValueChange={(field, value) =>
                                      handleEditField(i, field, value)
                                    }
                                    onDelete={() => handleRemoveField(i)}
                                    listing={listing}
                                  />
                                </Box>
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </>
        )}
      </Box>
    </>
  );
};

export default GuidebookEditor;

export default {
  apiGateway: {
    REGION: "us-east-1",
    URL: process.env.REACT_APP_API_URL,
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
  s3: {
    REGION: "us-east-1",
    BUCKET: process.env.REACT_APP_S3_BUCKET,
    URL: `https://${process.env.REACT_APP_S3_BUCKET}.s3.amazonaws.com/public/`,
  },
  ws: {
    URL: process.env.REACT_APP_WS_API_URL,
  },
};

export const THEME = {
  primary: "#0D568C",
  secondary: "#2F7AFB",
  warning: "#FFCC00",
  error: "#CA2033",
  subdued: "#6D7175",
  typography: "rgba(60, 60, 67, 0.3)",
  emailDarkAccent: "#F1B929",
  text: "#202223",
  textSecondary: "rgba(0, 0, 0, 0.54)",
  bg: "#f2f2f2",
  bgDisabled: "rgba(114, 126, 135, 0.101961)",
  inputBg: "rgba(67, 84, 96, 0.0392157)",
  sms: "#3ADC61",
  airbnb: "#FF585B",
  vrbo: "#1C4695",
  homeaway: "#1C4695",
  bookingcom: "#1C4695",
  email: "#357DF9",
  whatsapp: "#25D366",
  grey: "#435460",
  messageMe: "#E3E5E7",
  mutedGreen: "#9AD4D6",
  mutedRed: "#DBCBD8",
  cannedResponseTypeColors: {
    ai: "#6E44FF",
    bp: "#2F7AFB",
    experience: "#25D366",
    other: "#202223",
  },
  AI: {
    color: "#6E44FF",
    color2: "#4A2DB0",
    bg: "#F1EDFF",
    bgDark: "rgba(110, 68, 255, 0.30)",
    hoverBG: "#e8e4f9",
  },
  chekin: {
    bg1: "#FFFFFF",
    bg2: "#FFFFFF",
  },
  superhog: {
    bg1: "#3F3D54",
    bg2: "#3662E3",
  },
  autohost: {
    bg1: "#174072",
    bg2: "#ED40FF",
  },
  seam: {
    bg1: "#1f173b",
    bg2: "#592BFF",
  },
  white: "#FFF",
  satisfaction: {
    unsatisfied: {bg: "rgba(174, 39, 30, 0.1)", color: "rgba(174, 39, 30, 1)"},
    somewhat_satisfied: {
      bg: "rgba(125, 128, 8, 0.1)",
      color: "rgba(125, 128, 8, 1)",
    },
    neutral: {bg: "rgba(104, 104, 104, 0.1)", color: "rgba(104, 104, 104, 1)"},
    satisfied: {bg: "rgba(26, 133, 140, 0.1)", color: "rgba(26, 133, 140, 1)"},
    highly_satisfied: {
      bg: "rgba(25, 130, 64, 0.1)",
      color: "rgba(25, 130, 64, 1)",
    },
  },
  spacing: {
    xxs: 0,
    xs: 4,
    sm: 8,
    md: 12,
    lg: 20,
    xl: 30,
    xxl: 60,
  },
  transitions: {
    mobileScreens: 300,
  },
  customTypography: {
    fontFamily:
      '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
    title1: {
      color: "#435460",
      fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
      fontWeight: 600,
      fontSize: 17,
      lineHeight: "22px",
    },
    title2: {
      color: "#202223",
      fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
      fontWeight: 500,
      fontSize: 15,
      lineHeight: "22px",
      letterSpacing: "-0.36px",
    },
    dialogHeader: {
      fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
      fontWeight: 400,
      fontSize: 20,
      lineHeight: "28px",
      color: "#202223",
    },
    dialogHeaderVariant: {
      fontFamily:
        '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
      fontWeight: 600,
      fontSize: 26,
      lineHeight: "32px",
      color: "#202223",
    },
  },
};

export const STAGE_COLORS = {
  inquiry: "#425460",
  lead: "#FF585B",
  opportunity: "#FFB100",
  closed_won: "#00A740",
  closed_lost: "#A4D5F8",
  waiting_lost: "#E09BFF",
};

export const BOOKING_STATUS_COLORS = {
  cancelled: "#FFB100",
  confirmed: "#00A740",
  pending: "#A4D5F8",
  checked_in: "#E09BFF",
  declined: "#F34646",
};

export const MESSAGE_SENDER_COLORS = {
  user: "#212529",
  other_user: "#435460",
  automessage: "#9AD4D6",
  chatbot: "#6E44FF",
  auto_chatbot: "#6E44FF",
};

export const FIXED_SIZES = {
  guests: 110,
  users: 72,
  branding: 72,
  listings: 106,
  listings_dense: 70,
  connected_channel_accounts: 70,
  reports: 50,
  message_channel: {width: 24, height: 24},
  notifications: 70,
  experiences: 36,
  resource_item: 88,
  copy_existing_resource_item: 64,
  dashboard_sm_card: 326,
  dashboard_revenue_card: 240,
  dashboard_AI_card: 250,
  dashboard_bp_card: 385,
};

export const desktopSizes = {
  navbar: 80,
  listPanel: 300,
};

export const tabletSizes = {
  listPanel: 300,
};

export const mobileSizes = {
  navbar: 80,
  listPanel: "100%",
  detailsPanel: "100%",
  actionPanel: "100%",
};

// Integrations allowed to edit listings (i.e. listing content, ranges...)
export const ALLOWED_INTEGRATIONS_FOR_EDITING = ["bks", "bkp", "ab"];
export const ALLOWED_INTEGRATIONS_FOR_CALENDAR_EDITING = ["bkp"];
export const ALLOWED_INTEGRATIONS_FOR_DISTRIBUTION = ["bkp"];
export const ALLOWED_INTEGRATIONS_FOR_MANAGING = ["bkp"];

export const ICON_S3_URL = "https://enso-icons.s3.amazonaws.com";
export const SUPPORT_EMAIL = "support@ensoconnect.com";

import React, {useEffect, useState} from "react";
import FilledTextField from "core/inputs/FilledTextField";
import PrimaryButton from "core/buttons/PrimaryButton";
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Link,
  Button,
} from "@material-ui/core";
import FilledSelect from "core/selects/FilledSelect";
import {flushSync} from "react-dom";
import {Rating} from "@material-ui/lab";
import {THEME} from "configuration/settings";
import SearchIcon from "@material-ui/icons/Search";
import CreateIcon from "@material-ui/icons/Create";

const defaultImage = "https://via.placeholder.com/400"; // Placeholder image URL

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  searchBar: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    alignItems: "center",
    justifyContent: "space-between",
  },
  searchFieldsContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  searchField: {
    marginRight: theme.spacing(1),
    flexGrow: 1,
  },
  radiusField: {
    width: 100,
    marginRight: theme.spacing(1),
  },
  typeField: {
    width: 150,
    marginRight: theme.spacing(1),
  },
  generateButton: {
    marginLeft: theme.spacing(2),
    whiteSpace: "nowrap",
  },
  placeList: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    flex: 1,
    overflowY: "auto",
  },
  placeCard: {
    cursor: "pointer",
    width: "calc(20% - 16px)",
    height: "200px",
    boxSizing: "border-box",
    padding: "1px",
  },
  selectedPlaceCard: {
    cursor: "pointer",
    width: "calc(20% - 16px)",
    height: "200px",
    border: `2px solid ${theme.palette.secondary.main}`,
    boxSizing: "border-box",
  },
  media: {
    height: 65,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  multiLineEllipsis: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis",
  },
  summaryContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  summaryText: {
    flexGrow: 1,
    color: THEME.AI.color,
  },
}));

const TopPlaces = ({
  location,
  submitPlace,
  selectedPlaces,
  setSelectedPlaces,
  queries,
}) => {
  const classes = useStyles();
  const [places, setPlaces] = useState([]);
  const [searchRadius, setSearchRadius] = useState(
    queries?.[0]?.search_radius || 5,
  ); // Default radius in km
  const [placeType, setPlaceType] = useState(queries?.[0]?.place_type || "");
  const [query, setQuery] = useState(queries?.[0]?.query || "");
  const [showSearch, setShowSearch] = useState(false);
  const MAX_NUM_PLACES = 10

  useEffect(() => {
    if (places?.length === 0) {
      getTopPlaces();
    }
  }, []);

  const getTopPlaces = () => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps API is not loaded yet.");
      return;
    }

    const service = new window.google.maps.places.PlacesService(
      document.createElement("div"),
    );

    const request = {
      location: new window.google.maps.LatLng(location.lat, location.lng),
      radius: searchRadius * 1000, // Radius in meters
    };

    if (placeType !== "" && placeType !== "Any Type") {
      request.type = placeType;
    }

    if (query.trim() !== "") {
      request.keyword = query.trim();
    }

    service.nearbySearch(request, (results, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        results
      ) {
        const topPlaces = results.map((place) => {
          /*const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
            new window.google.maps.LatLng(location.lat, location.lng),
            place.geometry.location
          );*/
          const addressComponents = place.address_components || [];
          const getAddressComponent = (type) => {
            const component = addressComponents.find((component) =>
              component.types.includes(type),
            );
            return component ? component.long_name : "";
          };
          const distance = 100;
          return {
            id: place.place_id,
            address: {
              apt_number: getAddressComponent("subpremise"),
              street: `${getAddressComponent("street_number")} ${getAddressComponent(
                "route",
              )}`,
              city: getAddressComponent("locality"),
              state: getAddressComponent("administrative_area_level_1"),
              country: getAddressComponent("country"),
              zip_code: getAddressComponent("postal_code"),
              lat: place.geometry?.location?.lat().toString() ?? null,
              lng: place.geometry?.location?.lng().toString() ?? null,
              formatted: place.formatted_address,
            },
            image:
              place.photos && place.photos.length > 0
                ? place.photos[0].getUrl()
                : "",
            rating: place.rating,
            website: place.website,
            display_name: place.name ?? place.formatted_address,
            reviews: place.reviews
              ? place.reviews.filter((review) => review.rating >= 4)
              : [],
            price_level: place.price_level,
            star_rating: place.rating,
            vicinity: place.vicinity || "",
            distance: Math.round(distance), // Distance in meters
          };
        });
        setPlaces(topPlaces);
        setShowSearch(false); // Hide search inputs after updating results
      } else {
        console.error("PlacesService request failed:", status);
      }
    });
  };

  const handlePlaceSelect = (place) => {
    const isSelected = selectedPlaces.some((p) => p.id === place.id);
    if (isSelected) {
      setSelectedPlaces(selectedPlaces.filter((p) => p.id !== place.id));
    } else {
      setSelectedPlaces([...selectedPlaces, place]);
    }
  };

  const isPlaceSelected = (placeId) => {
    return selectedPlaces.some((p) => p.id === placeId);
  };

  return (
    <div className={classes.container}>
      <div className={classes.summaryContainer}>
        <Typography variant="body1" className={classes.summaryText}>
          Got {places.length} results for {placeType || query || "any type"}{" "}
          within a {searchRadius} km radius of the property.
        </Typography>
        <Link
          color="inherit"
          variant="body1"
          style={{color: THEME.AI.color}}
          onClick={() => setShowSearch(!showSearch)}
        >
          {showSearch ? "Hide Search Options " : "Show Search Options "}
          <SearchIcon style={{fontSize: 16}} />
        </Link>
      </div>
      {showSearch && (
        <div className={classes.searchBar}>
          <div className={classes.searchFieldsContainer}>
            <FilledTextField
              placeholder="Search places (e.g., restaurants, parks, etc.)"
              value={query}
              label="Search places"
              onChange={(e) => setQuery(e.target.value)}
              className={classes.searchField}
            />
            <FormControl className={classes.typeField}>
              <FilledSelect
                value={placeType}
                onChange={(e) => setPlaceType(e.target.value)}
                label="Place Type"
              >
                <MenuItem value="">Any Type</MenuItem>
                <MenuItem value="restaurant">Restaurant</MenuItem>
                <MenuItem value="park">Park</MenuItem>
                <MenuItem value="museum">Museum</MenuItem>
                <MenuItem value="shopping_mall">Shopping</MenuItem>
                <MenuItem value="tourist_attraction">Attractions</MenuItem>
                {/* Add more types as needed */}
              </FilledSelect>
            </FormControl>
            <FilledTextField
              placeholder="Radius (km)"
              type="number"
              label="Search radius"
              value={searchRadius}
              onChange={(e) => setSearchRadius(e.target.value)}
              className={classes.radiusField}
            />
          </div>
          <PrimaryButton
            onClick={getTopPlaces}
            label="Update Results"
            className={classes.generateButton}
          />
        </div>
      )}
      <div className={classes.placeList}>
        {places.map((place) => (
          <Card
            key={place.id}
            className={
              isPlaceSelected(place.id)
                ? classes.selectedPlaceCard
                : classes.placeCard
            }
            

          >
            <CardActionArea disabled={(selectedPlaces?.length === 10) && !isPlaceSelected(place.id)} onClick={() => handlePlaceSelect(place)}>
              <CardMedia
                className={classes.media}
                image={place.image || defaultImage}
                title={place.display_name}
              />
              <CardContent style={{padding: 10}}>
                <Typography variant="h1" className={classes.multiLineEllipsis}>
                  {place.display_name}
                </Typography>
                <div style={{display: "flex", alignItems: "center"}}>
                  <Rating
                    name="read-only"
                    value={place.rating || 0}
                    readOnly
                    size="small"
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{marginLeft: 8}}
                  >
                    {place.rating ? place.rating.toFixed(1) : "N/A"}
                  </Typography>
                </div>
                {place.vicinity && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    className={classes.multiLineEllipsis}
                  >
                    {place.distance}m - {place.vicinity}
                  </Typography>
                )}
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default TopPlaces;
